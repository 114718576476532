.snipcart {
    font-family: 'Poppins', sans-serif;
}

.snipcart__font--large {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.snipcart-item-line {
    background-color: transparent;
}

.snipcart-item-line__container {
    padding: 20px;
    margin: 0 15px 24px;
    border-radius: 10px;
    background-color: #fff;
}

.snipcart-item-line__media {
    min-width: 90px;
    max-width: 90px;
    margin-right: 10px;
}

.snipcart-base-button__label {
    font-weight: 700;
    font-size: 16px;
}

.snipcart-form__select {
    height: 40px;
    padding: 10px;
    border-radius: 6px;
}

.snipcart-item-quantity__quantity {
    border-radius: 6px;
    height: 42px;
}

#snipcart {
    --color-default: #1c1c1c;
    --color-icon: var(--color-default);

    --color-buttonPrimary: #1c1c1c;
    --color-buttonPrimary-hover: var(--color-buttonPrimary);
    --color-buttonPrimary-active: var(--color-buttonPrimary);
    --color-buttonPrimary-focus: var(--color-buttonPrimary);
    --color-buttonPrimary-disabled: var(--color-buttonPrimary);
    --color-buttonPrimary-success: var(--color-buttonPrimary);
    --color-buttonPrimary-error: var(--color-buttonPrimary);

    --color-buttonSecondary: #1c1c1c;
    --color-buttonSecondary-hover: var(--color-buttonSecondary);
    --color-buttonSecondary-active: var(--color-buttonSecondary);
    --color-buttonSecondary-focus: var(--color-buttonSecondary);
    --color-buttonSecondary-disabled: var(--color-buttonSecondary);
    --color-buttonSecondary-success: var(--color-buttonSecondary);
    --color-buttonSecondary-error: var(--color-buttonSecondary);

    --color-inputIcon-focus: var(--color-default);

    --bgColor-buttonPrimary: #FFB22E;
    --bgColor-buttonPrimary-hover: #FFA100;
    --bgColor-buttonPrimary-active: var(--bgColor-buttonPrimary-hover);
    --bgColor-buttonPrimary-focus: var(--bgColor-buttonPrimary-hover);
    --bgColor-buttonPrimary-disabled: #FFD48A;
    --bgColor-buttonPrimary-success: var(--bgColor-buttonPrimary);
    --bgColor-buttonPrimary-error: var(--bgColor-buttonPrimary-disabled);

    --bgColor-buttonSecondary: var(--bgColor-buttonPrimary);
    --bgColor-buttonSecondary-hover: var(--bgColor-buttonSecondary);
    --bgColor-buttonSecondary-active: var(--bgColor-buttonSecondary);
    --bgColor-buttonSecondary-focus: var(--bgColor-buttonSecondary);
    --bgColor-buttonSecondary-disabled: var(--bgColor-buttonSecondary);
    --bgColor-buttonSecondary-success: var(--bgColor-buttonSecondary);
    --bgColor-buttonSecondary-error: var(--bgColor-buttonSecondary);

    --borderColor-input-hover: var(--bgColor-buttonPrimary);
    --borderColor-input-focus: var(--bgColor-buttonPrimary);

    --shadow-input-hover: none;
    --shadow-input-focus: none;
    --shadow-input-checked: none;
}


.snipcart-remove-discount {
    background-color: var(--bgColor-buttonPrimary-hover);
    color: white;
    font-weight: 500;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    border: 1px solid;
    cursor: pointer;
    margin-bottom: 40px;
}
